<template>
  <div class='indexbox'>
    <div class="bread">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/setting'}">Setting</el-breadcrumb-item>
        <el-breadcrumb-item>User</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button @click="show=true">Invite New Users</el-button>
    </div>
    <div class="searchbox">
      <div class="tablebox">
        <div v-loading='loading' class="draggable">
          <el-table :data="tableData" stripe>
            <el-table-column align='center' fixed label="Email" prop="email" show-overflow-tooltip/>
            <el-table-column align='center' label="User creation time" prop="create_time" show-overflow-tooltip/>
            <el-table-column align='center' label="Administrator or not" prop="is_main" show-overflow-tooltip>
              <template slot-scope="scope">
                <p v-if="scope.row.is_main==1">Yes</p>
                <p v-if="scope.row.is_main==0">No</p>
              </template>
            </el-table-column>
            <el-table-column align='center' label="Status" prop="status" show-overflow-tooltip>
              <template slot-scope="scope">
                <p v-if="scope.row.status==0">Pending</p>
                <p v-else>Opened</p>
              </template>
            </el-table-column>
            <el-table-column align='center' label="Operation" prop="status" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button size="small" type="primary" @click="setdata(scope.row)">Permission</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination v-show="total>0" :current-page.sync="page" :page-size="10" :total="total" background layout="total,prev, pager, next" @current-change='pagechage'/>
      </div>
    </div>

    <el-dialog :visible.sync="show" title="Invite New Users" width="30%">
      <el-form ref="form" :model="form" :rules="rules" class="formbox">
        <el-form-item prop="email">
          <span class="form_tit">Email</span>
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item class="btnbox">
          <el-button :loading='btn_loading' type="primary" @click.native.prevent="onSubmit">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['api_id']),
  },
  data() {
    return {
      loading: false,
      total: 0,
      page: 1,
      tableData: [],

      show: false,
      btn_loading: false,
      form: {
        email: '',
      },
      rules: {
        email: [
          {required: true, trigger: "blur", message: 'Please Enter'}
        ],
      },
    };
  },
  methods: {
    getlist() {
      this.loading = true
      this.$post({
        url: "/app/api_user/userList",
        data: {
          page: this.page,
          api_id: this.$store.state.user.api_id
        },
        success: (res) => {
          this.tableData = res.data.data
          this.total = res.data.total
          this.page = res.data.current_page
          setTimeout(() => {
            this.loading = false
          }, 200);
        },
        tip: () => {
          this.loading = false
        },
      })
    },
    pagechage(page) {
      this.page = page
      this.tableData = []
      this.getlist()
    },
    // 邀请好友
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.btn_loading = true
          this.$post({
            url: "/app/member/sendInviteEmail",
            data: {...this.form, api_id: this.api_id},
            success: (res) => {
              this.$message({
                message: 'Success',
                type: 'success'
              });
              this.form = {
                email: ''
              }
              this.show = false
              setTimeout(() => {
                this.btn_loading = false
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.btn_loading = false
              }, 1500);
            },
          })
        }
      });
    },
    setdata(data) {
      this.$router.push(`rules?id=${data.id}`)
    }
  },
  created() {
    this.getlist()
  },
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  height: 100%;

  .bread {
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    /deep/ .el-button {
      font-weight: 500;
      color: #3b3b3b;
      background-color: #d6f3e3;
      border-radius: 10px;
      border: none;
      padding: 7px 13px;

      span {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
      }
    }
  }

  .searchbox {
    width: 100%;
    padding: 20px;
  }

  .tablebox {
    background-color: #ffffff;
    border-radius: 10px;
    width: 100%;
    padding: 20px 0;

    .draggable {
      /deep/ .el-table {
        .el-table__body-wrapper {
          .el-table__body {
            .el-table__row {
              .el-button {
                background-color: $color !important;
              }
            }
          }
        }
      }
    }
  }

  .formbox {
    /deep/ .el-form-item {
      margin-bottom: 30px !important;

      .el-select,
      .el-date-editor {
        width: 100%;
      }

      .el-form-item__content {
        width: 100%;

        .el-button {
          width: 100%;
          height: 48px;
          background: $color;
          border-radius: 5px;
          border-color: $color;
        }
      }
    }
  }
}
</style>
